import ApiService from "@/core/services/api.service";
import querystring from 'querystring';
import moment from "moment";
import {
    LARAVEL_DATE_TIME_FORMAT,
    LARAVEL_RAW_DATE_TIME_FORMAT,
} from "@/core/config/constant";

const BASE = 'WAYBILL/ENTRY/NEW';

// states
export const DELIVERER = `${BASE}/DELIVERER`;
export const WAYBILL_DATE = `${BASE}/WAYBILL_DATE`;
export const WAYBILL_TYPE = `${BASE}/WAYBILL_TYPE`;
export const WAYBILL_KIND = `${BASE}/WAYBILL_KIND`;
export const DOCUMENT_NO = `${BASE}/DOCUMENT_NO`;
export const SELECTED_COMPANY = `${BASE}/SELECTED_COMPANY`;
export const SELECTED_ORDERS = `${BASE}/SELECTED_ORDERS`;

// getters
export const GET_WAYBILL_DATE = `${BASE}/G/GET_WAYBILL_DATE`;
export const GET_WAYBILL_TYPE = `${BASE}/G/GET_WAYBILL_TYPE`;
export const GET_WAYBILL_KIND = `${BASE}/G/GET_WAYBILL_KIND`;
export const GET_DOCUMENT_NO = `${BASE}/G/GET_DOCUMENT_NO`;
export const GET_SELECTED_COMPANY = `${BASE}/G/GET_SELECTED_COMPANY`;
export const GET_SELECTED_ORDERS = `${BASE}/G/GET_SELECTED_ORDERS`;
export const GET_DELIVERER = `${BASE}/G/GET_DELIVERER`;

// mutations
export const SET_WAYBILL_DATE = `${BASE}/M/SET_WAYBILL_DATE`;
export const SET_WAYBILL_TYPE = `${BASE}/M/SET_WAYBILL_TYPE`;
export const SET_WAYBILL_KIND = `${BASE}/M/SET_WAYBILL_KIND`;
export const SET_DOCUMENT_NO = `${BASE}/M/SET_DOCUMENT_NO`;
export const SET_SELECTED_COMPANY = `${BASE}/M/SET_SELECTED_COMPANY`;
export const SET_SELECTED_ORDERS = `${BASE}/M/SET_SELECTED_ORDERS`;
export const SET_DELIVERER = `${BASE}/M/SET_DELIVERER`;

// actions

const state = {
    [WAYBILL_DATE]: moment(),
    [DOCUMENT_NO]: null,
    [WAYBILL_TYPE]: 0,
    [WAYBILL_KIND]: 0,
    [SELECTED_COMPANY]: null,
    [SELECTED_ORDERS]: [],
    [DELIVERER]: null,
};

const getters = {
    [GET_DELIVERER]: (state) => {
        return state[DELIVERER];
    },
    [GET_WAYBILL_DATE]: (state) => {
        return state[WAYBILL_DATE];
    },
    [GET_WAYBILL_TYPE]: (state) => {
        return state[WAYBILL_TYPE];
    },
    [GET_WAYBILL_KIND]: (state) => {
        return state[WAYBILL_KIND];
    },
    [GET_DOCUMENT_NO]: (state) => {
        return state[DOCUMENT_NO];
    },
    [GET_SELECTED_COMPANY]: (state) => {
        return state[SELECTED_COMPANY];
    },
    [GET_SELECTED_ORDERS]: (state) => {
        return state[SELECTED_ORDERS];
    },
};

const mutations = {
    [SET_DELIVERER]: (state, payload) => {
        state[DELIVERER] = payload;
    },
    [SET_WAYBILL_DATE]: (state, payload) => {
        state[WAYBILL_DATE] = payload;
    },
    [SET_WAYBILL_TYPE]: (state, payload) => {
        state[WAYBILL_TYPE] = payload;
    },
    [SET_WAYBILL_KIND]: (state, payload) => {
        state[WAYBILL_KIND] = payload;
    },
    [SET_DOCUMENT_NO]: (state, payload) => {
        state[DOCUMENT_NO] = payload;
    },
    [SET_SELECTED_COMPANY]: (state, payload) => {
        state[SELECTED_COMPANY] = payload;
    },
    [SET_SELECTED_ORDERS]: (state, payload) => {
        state[SELECTED_ORDERS] = payload;
    },

};

const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
};