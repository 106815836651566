import {
    MOMENT_SYSTEM_DATE_FORMAT,
    MOMENT_SYSTEM_DATE_TIME_FORMAT,
    MOMENT_SYSTEM_TIME_FORMAT,
    MOMENT_SYSTEM_DATE_DAY_FORMAT,
    MOMENT_SYSTEM_HOUR_AND_MINUTE, LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT
} from "@/core/config/constant";
import moment from "moment";

export const momentDateFormat  = (date) => {
    return date.format(MOMENT_SYSTEM_DATE_FORMAT);
};
export const momentDateDayFormat  = (date) => {
    return date.format(MOMENT_SYSTEM_DATE_DAY_FORMAT);
};

export const momentTimeFormat = (date) => {
    return date.format(MOMENT_SYSTEM_TIME_FORMAT);
};

export const laravelToMomentTimeFormat = (date) => {
    return moment(date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT);
};
export const momentHourAndMinuteFormat = (data) => {
    return data.format(MOMENT_SYSTEM_HOUR_AND_MINUTE);
}

export const momentDateTimeFormat = (dateTime) => {
    return dateTime.format(MOMENT_SYSTEM_DATE_TIME_FORMAT);
}

export const laravelDateToSystem = (dateTime) => {
    return moment(dateTime, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT);
}

export const laravelDateTimeToSystemDate = (dateTime) => {
    return moment(dateTime, LARAVEL_DATE_TIME_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT);
}
