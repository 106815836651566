import $ from 'jquery';

export default {
    data: function data() {
        return {
            mediaBase: process.env.VUE_APP_MEDIA_URL,
            baseUrl: process.env.VUE_APP_APP_URL,
        };
    },
    mounted(){
        // let bgImage = this.mediaBase + '/bg/ARKAPLAN.png';
        let ktContent = $('#kt_content');

        ktContent.css({
            // 'backgroundImage': "url('" + bgImage +"')"
            // 'background-image': 'linear-gradient(90deg, #46358d, #4374b7, #60bee3)'

        });
    },
};