import ApiService from "@/core/services/api.service";
import SecureLS from "secure-ls";

const ls = new SecureLS({encodingType: 'aes', isCompression: true});

export const BASE = 'MENU';

export const BUNDLE = `${BASE}/BUNDLE`;

export const GET_SAMPLE_MENU_BADGE_NO = `${BASE}/A/GET_SAMPLE_MENU_BAGET_NO`;


const state = {
    [BUNDLE]: {
        'menu-sample-list': 0,
        'menu-sample-tasks': 0,
        'menu-sample-samples': 0,
        'system-notification': 0,
        'menu-modelHouse': 0,
        'menu-model-house-tasks': 0,
        'menu-export-follow-tasks': 0,
        'menu-export-follow': 0,
    }
};

const getters = {};

const mutations = {};
const checkUserGrant = function (module, actions, strict = false) {
    try {
        let authUser = ls.get('user') ? JSON.parse(ls.get('user')) : null;

        if (!authUser) {
            return false;
        }


        if (!strict) {
            // checking user is super admin
            let isSuperAdmin = authUser.scopes.find(subItem => subItem.model === 'Staff' && subItem.action === 'superAdmin');

            if (isSuperAdmin) {
                return true;
            }
        }

        actions = actions.map(action => _.lowerCase(action));
        let scopes = _.map(authUser.scopes.filter(subItem => _.lowerCase(subItem.model) === _.lowerCase(module)), 'action');

        if (scopes.includes('all')) {
            return true;
        } else {
            let item = scopes.find(item => actions.includes(_.lowerCase(item)));
            return !!item;
        }
    } catch (e) {
        return false;
    }

}
const actions = {
    [GET_SAMPLE_MENU_BADGE_NO]: (context) => {
        let fabricRequestCount = 0;
        let accessoryRequestCount = 0;
        let invoiceCount = 0;
        let sampleCount = 0;
        let sampleTaskCount = 0;
        let sampleListCount = 0;
        let modelHouseCount = 0;
        let notificationCount = 0;
        let followingExportCount = 0;

        if (checkUserGrant('Staff', ['collectionResponsible', 'customerRepresentative'], true)) {
            ApiService.get(`api/samples-by-user-count`, '', {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "text/plain",
            }).then(response => {
                sampleCount = response.data;
            });

            ApiService.get(`api/samples-by-user-count`, '', {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "text/plain",
            }).then(response => {
                sampleTaskCount = response.data;
            });

            ApiService.get(`api/samples?count=1&is_completed=0&sample_status_id=1`, '', {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "text/plain",
            }).then(response => {
                sampleListCount = response.data.count;
            });
        }

        if (checkUserGrant('Staff', ['modelHouseChief', 'modelHouseStaff'], true)) {
            ApiService.get(`api/model-house/tasks?count=true`).then(response => {
                modelHouseCount = response.data && response.data.data ? response.data.data.length : 0;
            });
        }

        ApiService.get(`api/notification-count`)
            .then(response => {
                notificationCount = response.data.count;
            });

        if (checkUserGrant('Staff', ['brandChief' ,'brandManager'], true)) {
            ApiService.get(`api/fabric-requests?count=1&is_requested=0`, '', {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "text/plain",
            }).then(response => {
                fabricRequestCount = response.data;
            });
        }

        if (checkUserGrant('Invoice', ['invoiceManager', 'invoiceChief', 'preAccount'], true)) {
            ApiService.get(`api/invoice-count`)
                .then(response => {
                    invoiceCount = response.data.count;
                });
        }

        if (checkUserGrant('Staff', ['accessoryStaff'], true)) {
            ApiService.get(`api/accessory-requests?count=1&is_requested=0`, '', {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "text/plain",
            }).then(response => {
                accessoryRequestCount = response.data;
            });
        }

        if (checkUserGrant('Staff', ['followingExport'], true)) {
            ApiService.get(`api/exports/packing-task-list?count=1`, '', {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "text/plain",
            }).then(response => {
                followingExportCount = response.data;
            });
        }

        setTimeout(() => {
            context.state[BUNDLE]['menu-sample-tasks'] = sampleCount + fabricRequestCount + accessoryRequestCount;
            context.state[BUNDLE]['menu-sample-list'] = sampleTaskCount;
            context.state[BUNDLE]['menu-sample-samples'] = sampleCount + fabricRequestCount + accessoryRequestCount;
            context.state[BUNDLE]['system-notification'] = notificationCount;
            context.state[BUNDLE]['menu-modelHouse'] = modelHouseCount;
            context.state[BUNDLE]['menu-model-house-tasks'] = modelHouseCount;
            context.state[BUNDLE]['menu-bill-tasks'] = invoiceCount;
            context.state[BUNDLE]['menu-bill'] = invoiceCount;
            context.state[BUNDLE]['menu-export-follow-tasks'] = followingExportCount;
            context.state[BUNDLE]['menu-export-follow'] = followingExportCount;
        }, 2000);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
