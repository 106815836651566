export function responseError (payload) {
    if (!payload) {
        return payload
    }

    let errorData;
    if (payload.status === 422) {
        errorData = payload.data;
    } else {
        errorData = payload.response != undefined ? payload.response.data : payload;
    }

    if (typeof errorData === 'string') {
        return errorData
    } else {
        let errorMessage = ''
        if (errorData && errorData.errors) {
            let messageArray = Object.values(errorData.errors)[0]
            errorMessage = messageArray[0]
        } else if (errorData && errorData.message) {
            errorMessage = errorData.message
        } else {
            let messageArray = Object.values(errorData)[0]
            errorMessage = messageArray[0]
        }
        return errorMessage
    }
}
