import ApiService from "@/core/services/api.service";
import querystring from 'querystring';

const BASE = 'ORDER/CRUD/';

export const EXPORT_URL = 'api/orders/list/export'

export const LOADING = `${BASE}/LOADING`;
export const ERROR = `${BASE}/ERROR`;
export const SUCCESS = `${BASE}/SUCCESS`;
export const ITEMS = `${BASE}/ITEM`;
export const CURRENT_ITEM = `${BASE}/CURRENT_ITEM`;
export const FILTER = `${BASE}/FILTER`;
export const ORDER_SUMMARY = `${BASE}/ORDER_SUMMARY`;

export const SET_LOADING = `${BASE}/M/LOADING`;
export const SET_ERROR = `${BASE}/M/ERROR`;
export const SET_SUCCESS = `${BASE}/M/SUCCESS`;
export const SET_ITEMS = `${BASE}/M/ITEMS`;
export const SET_CURRENT_ITEM = `${BASE}/M/CURRENT_ITEM`;
export const RESET_VALUES = `${BASE}/M/RESET_VALUES`;
export const SET_FILTER = `${BASE}/M/FILTER`;
export const SET_ORDER_SUMMARY = `${BASE}/M/ORDER_SUMMARY`;

// ACTIONS
export const GET_ITEMS = `${BASE}/GET_ITEMS`;
export const GET_ORDER_SUMMARY = `${BASE}/GET_ORDER_SUMMARY`;
export const EXPORT = `${BASE}/EXPORT`;
export const GET_ITEM_DETAIL_BY_ID = `${BASE}/GET_ITEM_DETAIL_BY_ID`;
export const CREATE_ITEM = `${BASE}/CREATE_ITEM`;
export const UPDATE_ITEM_BY_ID = `${BASE}/UPDATE_ITEM_BY_ID`;
export const DELETE_ITEM_BY_ID = `${BASE}/DELETE_ITEM_BY_ID`;
export const PATCH = `${BASE}/PATCH`;
export const HANDLE_INFINITE_SCROLL = `${BASE}/HANDLE_INFINITE_SCROLL`;

const state = {
    items: null,
    currentItem: null,
    loading: false,
    error: null,
    success: null,
    filter: null,
    page: 1,
    order_summary: null,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    },
    [ITEMS]: (state) => {
        return state.items;
    },
    [CURRENT_ITEM]: (state) => {
        return state.currentItem;
    },
    [FILTER]: (state) => {
        return state.filter;
    },
    [ORDER_SUMMARY]: (state) => {
        return state.order_summary;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload;
    },
    [SET_SUCCESS]: (state, payload) => {
        state.success = payload;
    },
    [SET_ITEMS]: (state, payload) => {
        state.items = payload;
    },
    [SET_CURRENT_ITEM]: (state, payload) => {
        state.currentItem = payload;
    },
    [SET_ORDER_SUMMARY]: (state, payload) => {
        state.order_summary = payload;
    },
    [SET_FILTER]: (state, payload) => {
        state.filter = payload;
    },
    [RESET_VALUES]: (state) => {
        state.success = null;
        state.error = null;
        state.loading = false;
        state.items = null;
        state.currentItem = null;
        state.page = 1;
    },
};

const actions = {
    /**
     * This function will allow to do behind the process of infinite scroll
     *
     * NOTE: To use this action data content must be send with payload
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>|*}
     */
    [HANDLE_INFINITE_SCROLL]: (context, payload) => {
        if (!('filters' in payload) || !('page' in payload.filters)) {
            throw "To use the infinite feature, please pass filters with page in payload";
        }

        if (payload.$state == null) {
            context.commit(SET_ITEMS, {});
            context.state.page = 1;
        }

        let items = {...context.state.items};

        if (Number(items.last_page) === Number(items.current_page)) {
            context.commit(SET_LOADING, false);
            return new Promise(function (resolve) {
                resolve({
                    status: false,
                    end: true,
                    error: "End of the scroll"
                });
            });
        }

        if (document.activeElement !== document.body) document.activeElement.blur();
        context.commit(SET_LOADING, true);

        payload.filters.page = context.state.page;
        return ApiService.get(`${payload.url}?${querystring.stringify(payload.filters)}`).then(response => {
            if (!Object.keys(items).length) {
                items = {...response.data};
            } else {
                items.data = [...items.data, ...response.data.data];
            }
            delete response.data.data;
            items = {...items, ...response.data};
            if(Number(items.last_page) > context.state.page){
                context.state.page = items.current_page + 1;
            }
            context.commit(SET_ITEMS, items);
            context.commit(SET_LOADING, false);
            return {status: true, data: payload};
        }).catch(error => {
            context.commit(SET_LOADING, false);
            return {status: false, error};
        });
    },

    [GET_ITEMS]: (context, payload) => {
        let url = payload.url;
        let filters = payload.filters || null;
        if('showLoading' in payload && payload.showLoading === true){
            context.commit(SET_LOADING, true);
        }

        let serviceUrl = url;

        if (filters) {
            serviceUrl = `${url}?` + querystring.stringify(filters);
        }

        return ApiService.get(serviceUrl)
            .then((response) => {
                if('showLoading' in payload && payload.showLoading === true){
                    context.commit(SET_LOADING, false);
                }
                return {status: true, data: response.data};
            }).catch(error => {
                context.commit(SET_ERROR, error);
                return {status: false, data: error};
            });
    },

    [GET_ORDER_SUMMARY]: (context, payload) => {
        let url = payload.url;
        let filters = payload.filters || null;
        let serviceUrl = url;
        if (filters) {
            serviceUrl = `${url}?` + querystring.stringify(filters);
        }

        return ApiService.get(serviceUrl)
            .then((response) => {
                context.commit(SET_ORDER_SUMMARY, response.data);
            }).catch(error => {
                context.commit(SET_ERROR, error);
            });
    },
    [GET_ITEM_DETAIL_BY_ID]: (context, payload) => {
        let url = payload.url;
        if('showLoading' in payload){
            context.commit(SET_LOADING, true);
        }
        return ApiService.get(url)
            .then((response) => {
                if('showLoading' in payload){
                    context.commit(SET_LOADING, false);
                }
                return {status: true, data: response.data};

            }).catch((error) => {
                console.error(error);
                return {status: false, data: error};
        });
    },
    [PATCH]: (context, payload) => {
        let url = payload.url;
        let contents = payload.contents;
        if('showLoading' in payload && payload.showLoading){
            context.commit(SET_LOADING, true);
        }
        return ApiService.patch(url, contents).then((response) => {
            if('showLoading' in payload && payload.showLoading){
                context.commit(SET_LOADING, false);
            }
            return {status: true, data: response.data};

        }).catch((error) => {
            context.commit(SET_ERROR, error);
            return {status: false, error: error};
        });
    },
    [CREATE_ITEM]: (context, payload) => {
        let url = payload.url;
        let contents = payload.contents;
        if('showLoading' in payload && payload.showLoading){
            context.commit(SET_LOADING, true);
        }
        return ApiService.post(url, contents).then((response) => {
            if('showLoading' in payload && payload.showLoading){
                context.commit(SET_LOADING, false);
            }
            return {status: true, data: response.data};

        }).catch((error) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_ERROR, error);
            return {status: false, data: error};
        });
    },
    [UPDATE_ITEM_BY_ID]: (context, payload) => {
        let url = payload.url;
        let contents = payload.contents;
        if('showLoading' in payload && payload.showLoading){
            context.commit(SET_LOADING, true);
        }
        return ApiService.put(url, contents)
            .then((_) => {
                if('showLoading' in payload && payload.showLoading){
                    context.commit(SET_LOADING, false);
                }
                return {status: true, data: _.data};
            }).catch((error) => {
                return {status: false, data: error};
        });
    },
    [DELETE_ITEM_BY_ID]: (context, payload) => {
        let url = payload.url;
        if('showLoading' in payload && payload.showLoading){
            context.commit(SET_LOADING, true);
        }
        return ApiService.delete(url).then((response) => {
            if('showLoading' in payload && payload.showLoading){
                context.commit(SET_LOADING, false);
            }
            return {status: true, data: response.data};
        }).catch((error) => {
            context.commit(SET_ERROR, error);
            return {status: false, data: error};
        });
    },
    [EXPORT]: (context, payload) => {
        let url = EXPORT_URL;
        let filters = payload.filters || null;
        let serviceUrl = url;
        if (filters) {
            serviceUrl = `${url}?` + querystring.stringify(filters);
        }
        context.commit(SET_LOADING, true);
        let config = {
            responseType: 'blob',
        }
        return ApiService.get(serviceUrl,"", config).then((response) => {
            context.commit(SET_LOADING, false);
            return {status: true, data: response.data};
        }).catch((error) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_ERROR, error);
            return {status: false, data: error};
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
