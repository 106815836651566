import moment from "moment";

const BASE = 'ORDER/EDIT';
export const GET_ORDER_SIZE_EXPORT = `${BASE}/GET_ORDER_SIZE_EXPORT`;
export const FORM = `${BASE}/FROM`;
export const EXPENSE_DETAIL = `${BASE}/EXPENSE_DETAIL`;

export const SET_FORM = `${BASE}/SET_FORM`;
export const SET_EXPENSE_DETAIL = `${BASE}/SET_EXPENSE_DETAIL`;
export const RESET_FORM = `${BASE}/RESET_FORM`;
export const SET_ORDER_SIZE_EXPORT = `${BASE}/SET_ORDER_SIZE_EXPORT`;
export const DELETE_ORDER_SIZE_AND_COLOR_SELECTED_EXPORT_DATE = `${BASE}/DELETE_ORDER_SİZE_AND_COLOR_SELECTED_EXPORT_DATE`;
export const DELETE_ORDER_SIZE_AND_COLOR_EXPORT_DATE = `${BASE}/DELETE_ORDER_SİZE_AND_COLOR_EXPORT_DATE`
export const SET_ORDER_SIZE_AND_COLOR_SELECTED_EXPORT_DATE = `${BASE}/SET_ORDER_SIZE_AND_COLOR_SELECTED_EXPORT_DATE`
export const SET_ORDER_SIZE_AND_COLOR_EXPORT_DATE = `${BASE}/SET_ORDER_SIZE_AND_COLOR_EXPORT_DATE`

const state = {
    [FORM]: {
        id: null,
        amount: null,
        order_number: null,
        image: null, // not exist
        image_link: null,
        supplier_company_id: null,
        fabric_supply_type_id: -1,
        esc_table_id: null,
        esc: null, // not exist
        company_id: null,
        status_id: null,
        model: null,
        sample_id: null,
        sample: null,
        po_number: null,
        currency_unit_id: '1',
        sale_price: null,
        qc_user_id: null,
        description: null,
        order_export_dates: [
            {
                export_date: moment(),
                amount: null,
            }
        ],
        order_expenses: [],
        selected_expenses: [], // not exist
        order_fabrics: [
            {
                order_id: null,
                deadline_start: moment(),
                deadline_end: moment(),
                type: null,
                weight: null,
                width: null,
                pastal: null,
                unit_price: null,
                currency_unit_id: null,
                planned: null,
                actual: null,
                selected_order_fabric_compositions: [],
                order_fabric_compositions: []
            },
        ],
        order_size_and_colors: [
            {
                color_number: null,
                color_name: null,
                body_sizes: {},
                total: null,
                export_total: null,
                is_submit_order_export: false,
                selected_dates: {},
                export_dates: []
            }
        ],
        is_submitted: false, // not exist
        csr: {
            disney_licences: {
                disney_id: null,
                license_id: null,
            },
            join_life: [],
        },
    },
    [EXPENSE_DETAIL]: {}
};

const getters = {
    [GET_ORDER_SIZE_EXPORT]: (state) => (index) => {
        return state[FORM].order_size_and_colors[index].is_submit_order_export
    },
};

const mutations = {
    [SET_FORM]: (state, payload) => {
        state[FORM] = payload;
    },
    [SET_EXPENSE_DETAIL]: (state, payload) => {
        state[EXPENSE_DETAIL] = payload;
    },
    [RESET_FORM]: (state) => {
        state[FORM] = {
            id: null,
            amount: null,
            qr_code_link: null,
            order_number: null,
            image: null, // not exist
            image_link: null,
            esc_table_id: null,
            fabric_supply_type_id: -1,
            supplier_company_id: null,
            esc: null, // not exist
            company_id: null,
            status_id: null,
            model: null,
            sample_id: null,
            sample: null,
            po_number: null,
            currency_unit_id: '1',
            sale_price: null,
            qc_user_id: null,
            description: null,
            order_export_dates: [
                {
                    export_date: moment(),
                    amount: null,
                }
            ],
            order_expenses: [],
            selected_expenses: [], // not exist
            order_fabrics: [
                {
                    order_id: null,
                    deadline_start: moment(),
                    deadline_end: moment(),
                    type: null,
                    weight: null,
                    width: null,
                    pastal: null,
                    unit_price: null,
                    currency_unit_id: null,
                    planned: null,
                    actual: null,
                    selected_order_fabric_compositions: [],
                    order_fabric_compositions: []
                },
            ],
            order_size_and_colors: [
                {
                    color_number: null,
                    color_name: null,
                    body_sizes: {},
                    total: null,
                    export_total: null,
                    selected_dates: {},
                    export_dates: []
                }
            ],
            user: {
                name: ''
            },
            is_submitted: false, // not exist
            csr: {
                disney_licences: {
                    disney_id: null,
                    license_id: null,
                },
                join_life: [],
            },
        };
    },
    [SET_ORDER_SIZE_EXPORT]: (state, payload) => {
        state[FORM].order_size_and_colors[payload.tmpOrderSizeAndColorId].is_submit_order_export = payload.value;
    },
    [DELETE_ORDER_SIZE_AND_COLOR_SELECTED_EXPORT_DATE]: (state, payload) => {
        state[FORM].order_size_and_colors[payload.tmpOrderSizeAndColorId].selected_dates.splice(payload.findSelectedIndex, 1);
    },
    [DELETE_ORDER_SIZE_AND_COLOR_EXPORT_DATE]: (state, payload) => {
        state[FORM].order_size_and_colors[payload.tmpOrderSizeAndColorId].export_dates.splice(payload.indexDate, 1);
        state[FORM].order_size_and_colors[payload.tmpOrderSizeAndColorId].is_submit_order_export = false;
    },
    [SET_ORDER_SIZE_AND_COLOR_SELECTED_EXPORT_DATE]: (state, payload) => {
        state[FORM].order_size_and_colors[payload.index].selected_dates = payload.selectedExportDate;
    },
    [SET_ORDER_SIZE_AND_COLOR_EXPORT_DATE]: (state, payload) => {
        state[FORM].order_size_and_colors[payload.tmpOrderSizeAndColorId].export_dates = payload.dates;
    },
};

const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
};
