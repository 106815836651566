import swal from 'sweetalert2/dist/sweetalert2.all.min.js';
import $ from "jquery";

export default {
    methods: {
        showApiResponseError(response) {
            if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
                this.sweetAlertError(response.data.message);
            } else {
                this.sweetAlertError(response.data);
            }
        },
        sweetAlertError(message) {
            let error = '';
            if (Array.isArray(message)) {
                error += `<ul>`;
                for (let i = 0; i < message.length; i++) {
                    error += `<li>${this.$t(message[i])}</li>`;
                }
                error += `</ul>`;
            } else if (typeof message === 'object') {

                if (message.hasOwnProperty('response') && message.response.hasOwnProperty('data') && message.response.data.hasOwnProperty('message')) {
                    error = this.$t(message.response.data.message);
                } else if (message.hasOwnProperty('message')) {
                    error = this.$t(message.message);
                } else {
                    error += `<ul>`;
                    for (let key in message) {
                        let currentValue = message[key];
                        error += `<li><b>${this.$t(key)} : </b> ${this.$t(currentValue)}</li>`;
                    }
                }
            } else if (typeof message === 'string') {
                let arrayMessage = message.split("-");

                if (arrayMessage.length > 1) {

                    let errorMessage = arrayMessage[0];

                    let params = arrayMessage[1].split("_");

                    console.log(errorMessage);
                    console.log(params);
                    error = this.$t(errorMessage, { param1: params[0] });

                } else {
                    error = this.$t(message);
                }
            }
            return swal.fire({
                icon: 'error',
                title: 'Oops...',
                iconHtml: '',
                html: this.$t(error),
            }).then(() => {
                return true;
            });
        },
        sweetAlertWarning(warning) {
            let self = this;
            swal.fire({
                icon: 'warning',
                html: this.$t(warning),
            }).then(() => {
                if (self.setLoading !== undefined) {
                    self.setLoading(false);
                }
            });
        },
        sweetAlertSuccess(successMessage, callBack = null, configs = {}) {
            this.sweetAlertClose();
            let self = this;
            let options = {
                // icon: 'success',
                imageUrl: this.getIconByKey('icons.general.approve'),
                title: this.$t('general.congratulation'),
                html: this.$t(successMessage),
                confirmButtonText: this.$t('general.ok').toUpperCase(),
                className: 'rounded-full',
                confirmButtonClass: 'btn btn-outline-primary btn-pill',
                // customClass: {
                //     confirmButton: ' btn btn-outline-dark rounded-full'
                // },
                // buttons: {
                //     confirm : {text:this.$t('general.ok').toUpperCase(),className:'btn btn-outline-dark'},
                //     cancel : {text:this.$t('general.ok'),className:'btn btn-outline-success'},
                //     ok: {text:this.$t('general.ok').toUpperCase(), className:'btn btn-outline-dark'}
                // },
            };
            return swal.fire({
                ...options, ...configs
            }).then(function () {
                if (self.setLoading !== undefined) {
                    self.setLoading(false);
                }
                if (callBack === null) {
                    return true;
                }
                callBack(self);
            });
        },
        sweetAlertConfirm(message, confirmCallback = null, cancelCallback = null, configs = {}) {
            let self = this;
            let options = {
                html: this.$t(message),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: self.$t('general.yes_delete_it'),
                cancelButtonText: self.$t('general.no_cancel_it'),
                confirmButtonClass: 'btn btn-outline-primary btn-pill',
                cancelButtonClass: 'btn btn-outline-danger btn-pill',
                buttonsStyling: false
            };
            if (!('imageUrl' in configs)) {
                options['icon'] = 'warning';
            }
            if ('yes' in configs) {
                options.confirmButtonText = configs.yes;
            }
            if ('no' in configs) {
                options.cancelButtonText = configs.no;
            }
            return swal.fire({ ...options, ...configs }).then(function (result) {
                if (result.isConfirmed) {
                    if (confirmCallback) {
                        confirmCallback(self);
                    }
                    return true;
                } else {
                    if (cancelCallback) {
                        cancelCallback(self);
                    }
                    return false;
                }
            });
        },
        sweetAlertLoading() {
            $(document).find("#content-loading").attr('style', 'height: 100vh; display: inherit');
            $(document).find("#content-loading-except").addClass("content-blur");
        },
        sweetAlertClose() {
            setTimeout(() => {
                $(document).find("#content-loading").attr('style', 'height: 100vh; display: none !important');
                $(document).find("#content-loading-except").removeClass("content-blur");
            }, 100);
        },
    },
    watch: {
        loading(newValue, oldValue) {
            if (newValue === oldValue) {
                $(document).find("#content-loading").attr('style', 'height: 100vh; display: none !important');
                $(document).find("#content-loading-except").removeClass("content-blur");
                return;
            }
            if (newValue === true) {
                $(document).find("#content-loading").attr('style', 'height: 100vh; display: inherit');
                $(document).find("#content-loading-except").addClass("content-blur");
            } else {
                setTimeout(() => {
                    $(document).find("#content-loading").attr('style', 'height: 100vh; display: none !important');
                    $(document).find("#content-loading-except").removeClass("content-blur");
                }, 100);
            }
        },
        success(newValue, oldValue) {
            if (typeof newValue === 'string') {
                this.sweetAlertClose();
                this.sweetAlertSuccess(this.$t(newValue));
            } else {
                setTimeout(() => swal.close(), 500);
            }
        },
        error(newValue, oldValue) {
            if (newValue != null && !this.disabledErrorPopUp) {
                let self = this;
                setTimeout(() => {
                    this.sweetAlertClose();
                    self.sweetAlertError(newValue);
                }, 500);
            }
        }
    }
}
