export const MOMENT_SYSTEM_DATE_FORMAT = 'DD.MM.YYYY';
export const MOMENT_SYSTEM_DATE_DAY_FORMAT = 'DD.MM.YYYY dddd';
export const MOMENT_SYSTEM_DATE_TIME_FORMAT = 'DD.MM.YYYY hh:mm:ss';
export const MOMENT_SYSTEM_TIME_FORMAT = 'hh:mm:ss';
export const MOMENT_SYSTEM_HOUR_AND_MINUTE = 'hh:mm';

export const LARAVEL_TIME_FORMAT = 'hh:mm:ss';
export const LARAVEL_DATE_FORMAT = 'YYYY-MM-DD';
export const LARAVEL_DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const LARAVEL_RAW_DATE_TIME_FORMAT = "YYYY-MM-YY'T'hh:mm:ssZ";

export const KT_DATE_FORMAT = 'dd.mm.yyyy';
export const MOMENT_DATE_FORMAT_FOR_KT = 'DD.MM.YYYY';
