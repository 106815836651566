import ApiService from "@/core/services/api.service";
import querystring from 'querystring';
import moment from "moment";
import {
    LARAVEL_DATE_FORMAT,
    LARAVEL_DATE_TIME_FORMAT,
    LARAVEL_RAW_DATE_TIME_FORMAT,
} from "@/core/config/constant";

// GETTERS
export const MEETINGS = 'SAMPLE/CREATE/MEETING/MEETINGS';
export const ERROR = 'SAMPLE/CREATE/MEETING/ERROR';
export const LOADING = 'SAMPLE/CREATE/MEETING/LOADING';
export const MEETINGS_LIST = 'SAMPLE/CREATE/MEETING/MEETINGS_LIST';
export const PER_PAGE = 'SAMPLE/CREATE/MEETING/PER_PAGE';
export const PAGE_NUMBER = 'SAMPLE/CREATE/MEETING/PAGE_NUMBER';
export const SEARCH = 'SAMPLE/CREATE/MEETING/SEARCH';
export const START_DATE = 'SAMPLE/CREATE/MEETING/START_DATE';
export const END_DATE = 'SAMPLE/CREATE/MEETING/END_DATE';

// MUTATIONS
export const SET_MEETINGS = 'SAMPLE/CREATE/MEETING/M/MEETINGS';
export const SET_ERROR = 'SAMPLE/CREATE/MEETING/M/ERROR';
export const SET_LOADING = 'SAMPLE/CREATE/MEETING/M/LOADING';
export const SET_PER_PAGE = 'SAMPLE/CREATE/MEETING/M/PER_PAGE';
export const SET_PAGE_NUMBER = 'SAMPLE/CREATE/MEETING/M/PAGE_NUMBER';
export const SET_SEARCH = 'SAMPLE/CREATE/MEETING/M/SEARCH';
export const SET_START_DATE = 'SAMPLE/CREATE/M/START_DATE';
export const SET_END_DATE = 'SAMPLE/CREATE/M/END_DATE';


// ACTIONS

export const GET_MEETINGS = 'SAMPLE/CREATE/MEETING/A/GET_MEETINGS';
export const POST_MEETINGS = 'SAMPLE/CREATE/MEETING/A/POST_MEETINGS';
export const RESET_FILTERS = 'SAMPLE/CREATE/MEETING/A/RESET_FILTERS';


const state = {
    meetings: {},
    error: null,
    loading: false,
    perPage: 9999999,
    pageNumber: 1,
    search: '',
    startDate: moment().subtract(360, 'days'),
    endDate: moment().add(10, "days"),
};

const getters = {
    [MEETINGS]: state => {
        return state.meetings;
    },
    [ERROR]: state => {
        return state.error;
    },
    [LOADING]: state => {
        return state.loading;
    },
    [SEARCH]: state => {
        return state.search;
    },
    [START_DATE]: state => {
        return state.startDate;
    },
    [END_DATE]: state => {
        return state.endDate;
    },
    [PAGE_NUMBER]: state => {
        return state.pageNumber;
    },
    [PER_PAGE]: state => {
        return state.perPage;
    },
    [MEETINGS_LIST]: state => {
        let items = [];
        for(let key in state.meetings.data){
            /** @type {Object}*/
            let temp = state.meetings.data[key];
            temp.create_at = moment(temp.created_at, LARAVEL_RAW_DATE_TIME_FORMAT);
            temp.updated_at = moment(temp.updated_at, LARAVEL_RAW_DATE_TIME_FORMAT);
            temp.deleted_at = moment(temp.deleted_at, LARAVEL_RAW_DATE_TIME_FORMAT);
            temp.date_hour = moment(temp.date_hour, LARAVEL_DATE_TIME_FORMAT);
            items.push(temp);
        }
        return items;
    }
};

const mutations = {
    [SET_LOADING](state, payload) {
        state.loading = payload;
    },
    [SET_ERROR](state, payload) {
        state.error = payload;
    },
    [SET_MEETINGS](state, payload) {
        state.meetings = payload;
    },
    [SET_SEARCH](state, payload) {
        state.search = payload;
    },
    [SET_START_DATE](state, payload) {
        state.startDate = payload;
    },
    [SET_END_DATE](state, payload) {
        state.endDate = payload;
    },
    [SET_PER_PAGE](state, payload) {
        state.perPage = payload;
    },
    [SET_PAGE_NUMBER](state, payload) {
        state.pageNumber = payload;
    },

};

const actions = {
    [RESET_FILTERS]: (context) => {
        context.commit(SET_START_DATE, moment().subtract(365, 'days'));
        context.commit(SET_END_DATE, moment().add(7, 'days'));
        context.commit(SET_PER_PAGE, 10);
        context.commit(SET_PAGE_NUMBER, 1);
    },
    [GET_MEETINGS]: (context) => {
        context.commit(SET_LOADING, true);
        let filters = {
            per_page: context.state.perPage,
            page_number: context.state.pageNumber,
            search: context.state.search,
            start_date: moment(context.state.startDate).format(LARAVEL_DATE_TIME_FORMAT),
            end_date: moment(context.state.endDate).format(LARAVEL_DATE_TIME_FORMAT),
        };
        ApiService.get("api/meetings?" + querystring.stringify(filters)).then((response ) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_MEETINGS, response.data);
        }).catch((error) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_ERROR, error);
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};