export const amountFormat  = (number) => {
    if (!_.isNil(number) && !_.isUndefined(number) && Number(number) !== 0) {
        return new Intl.NumberFormat().format(Number(number).toFixed(2));
    }
    return 0;
};
export const priceFormat  = (number) => {
    if (!_.isNil(number) && !_.isUndefined(number) && Number(number) !== 0) {
        return new Intl.NumberFormat().format(Number(number).toFixed(3));
    }
    return 0;
};
export const exchangeRateFormat  = (number) => {
    if (!_.isNil(number) && !_.isUndefined(number) && Number(number) !== 0) {
        if(`${number}`.length>=5){
            return Number(number).toFixed(4).replace('.', ',');
        }else if(`${number}`.length===4){
            return Number(number).toFixed(4).replace('.', ',');
        }else if(`${number}`.length===3){
            return Number(number).toFixed(3).replace('.', ',');
        }else if(`${number}`.length===2){
            return Number(number).toFixed(2).replace('.', ',');
        }else{
            return Number(number).toFixed(4).replace('.', ',');
        }
    }
    return 0;
};
export const invoiceFormat  = (number) => {
    if (!_.isNil(number) && !_.isUndefined(number) && Number(number) !== 0) {
        return new Intl.NumberFormat().format(Number(number).toFixed(2));
    }
    return 0;
};