
const BASE = 'WAYBILL/ENTRY/EXIT';

// states
export const BUNDLE = `${BASE}/BUNDLE`;

//mutations
export const RESET_STATE = `${BASE}/RESET_STATE`;

// actions

const state = {
    [BUNDLE]: {
        exitType: 0,
        qr_link: null,
        productIdentification: 0,
        orderNo: 0,
        unitId: 0,
        storeId: 0,
        netIncomeAmount: null,
        netIncomeAmountUnitId: 0,
        productName: null,
        numberOfIncomeRoll: null,
        incomePartNumber: null,
        productColor: null,
        receiver: null,
        instruction_type_id: null,
        exit_document_no: null,
        exitInfo: [
            {
                exit_net_amount: null,
                tare_weight: null,
                exit_number_of_roll: null,
                exit_part_number: null,
            }
        ],
        totalAmount: {
            total_net_amount: 0,
            total_tare_weight: 0,
        }
    },
};
const getters = {};

const mutations = {
    [RESET_STATE]: (state) => {
        state[BUNDLE] = {
            waybillType: 0,
            billable: 0,
            productIdentification: 0,
            document_number: null,
            orderNo: 0,
            unitId: 0,
            netIncomeAmount: null,
            netIncomeAmountUnitId: 0,
            productName: null,
            numberOfIncomeRoll: null,
            incomePartNumber: null,
            productColor: null,
            receiver: null,
            exit_document_no: null,
            supplier_company_id: null,
            instructable_type: null,
            instructable_id: null,
            exitInfo: [
                {
                    exit_net_amount: null,
                    tare_weight: null,
                    exit_number_of_roll: null,
                    exit_part_number: null,
                }
            ],
            totalAmount: {
                total_net_amount: 0,
                total_tare_weight: 0,
            }
        };
    }
};

const actions = {};

export default {
    state,
    getters,
    mutations,
    actions
};
